






























import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TeacherHistoryHeader from '@/components/organisms/TeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentHistoryTotal from '@/components/organisms/StudentHistoryTotal.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LearningType, { LearningTypePage } from '@/mixins/historyv3/LearningType'
import FilterMode from '@/components/organisms/v3/FilterMode.vue'

@Component({
  components: {
    StudentHistorySidebar,
    TeacherHistoryHeader,
    ColoredBox,
    StudentHistoryTotal,
    TitleBase,
    TitleTextBase,
    DatePickerIcon,
    TeacherHistoryBottomPanel,
    ButtonBase,
    FilterMode,
  },
})
export default class LearningSituation extends Mixins(LocalMoment, LearningType) {
  private userId = Vue.prototype.$cookies.get('dataGdls').student?.userId || 0

  private modeCode = ''

  private selectDate: any = null

  private handleChangeMode(code: string) {
    this.modeCode = code
  }
  @Watch('modeCode')
  private async watchSelectMode() {
    Vue.prototype.$loading.start()
    if (this.selectDate) {
      await this.getResultSummaries({
        start: this.selectDate.start.format('YYYY-MM-DD'),
        end: this.selectDate.end.format('YYYY-MM-DD'),
      })
    } else {
      await this.getResultSummaries(this.defaulrtPeriodParam())
    }
    Vue.prototype.$loading.complete()
  }

  private totalStatusDatas = {}

  private period = ''

  private periodDate: { start: Date | null; end: Date | null } = { start: null, end: null }

  private onChangeRangeDate(params: { start: moment.Moment; end: moment.Moment }) {
    if (params.start > params.end) {
      alert('選択期間が不正です')
      return
    }
    this.selectDate = params
    this.getResultSummaries({ start: params.start.format('YYYY-MM-DD'), end: params.end.format('YYYY-MM-DD') })
  }

  private created(): void {
    if (this.userId === 0) return
    this.period = this.defaultPeriod()
    this.periodDate = this.defaultPeriodDate()
    this.getResultSummaries(this.defaulrtPeriodParam())
  }

  private defaultPeriod(): string {
    return this.beforeMonths(1).format('LL') + '~' + this.today().format('MM月DD日')
  }

  private defaultPeriodDate(): { start: Date; end: Date } {
    return { start: this.beforeMonths(1).toDate(), end: this.today().toDate() }
  }

  private defaulrtPeriodParam(): { start: string; end: string } {
    return { start: this.beforeMonths(1).format('YYYY-MM-DD'), end: this.today().format('YYYY-MM-DD') }
  }

  private async getResultSummaries(_params: { start: string; end: string }) {
    const isV3 = Vue.prototype.$gdlsCookiesV3?.isV3()
    const params: any = { startAt: _params.start, endAt: _params.end }
    if (!isV3 || this.currentLearningType === LearningTypePage.NormalV1) {
      params.classModeCode = 'TJ'
    } else if (this.currentLearningType === LearningTypePage.Normal || this.modeCode) {
      params.class_mode_code = this.currentLearningType === LearningTypePage.Normal ? 'AI' : this.modeCode
    }

    Vue.prototype.$http.httpWithToken
      .get(`${this.callApiV3 ? '/v3' : ''}/history/resultSummaries/${this.userId}`, {
        params,
      })
      .then((res: any) => {
        this.totalStatusDatas = FormatResultSummaries(res.data)
      })
  }
}
